const Heatmap = () => {
    return (
        <>
             <div className="mb-5" id="heatmap">
          <div className="d-flex justify-content-between">
            <h2 className=""> Pennsylvania Pollution Map 🗺️ </h2>
            <a href="https://colab.research.google.com/drive/1eLv3FN8Q_hDzdnrbVqdEik-LejGaI5UJ?usp=sharing"> 
              <btn className="btn btn-secondary"> see code 🔗 </btn>
            </a>
          </div>
          <ul>
            <li>
              <div className="">
                Multi-layer heatmap using state-wide data on water pollution, 6 air pollutants, cancer rate, death count, birth defects rate, population, automobile emission incorporated from 5 pollution data sources 
              </div>
            </li>
            <li>
              <div className="">
              Presented map to Philadelphia Climate Works, local non-profit advocate for environmental policies
              </div>
            </li> 
                
            <li>
              <div className="">
                Demo:
              </div>
            </li>
            <div className="img-thumbnail col-12 mt-3">
              <img src="heatmap.png" alt="our process" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Pollution in Pennsylvania</h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="heatmap-large.png" alt="our process" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Pollution around the city of Philadelphia</h5>
              </div>


          </ul>
        </div>
        </>
    )
}

export default Heatmap;