const Meh = () => {
    return (
        <>
            <div className="mb-5" id="meh">
          <div className="d-flex justify-content-between">
            <h2 className="">Meh~ 🗣️</h2>
            <a href="https://www.mehssage.us/home/"> 
              <btn className="btn btn-secondary"> try project 🔗 </btn>
            </a>
          </div>
          <ul>
            <li>
              <div className="">
                twitter but meh
              </div>
            </li>
            <li>
              <div className="">
              Users can: 
                <ul>
                  <li> register/authenticate account, select randomly generated pictures as profile pics</li>
                  <li> create posts, like/save/comment on posts </li>
                  <li> liked/saved posts will appear in liked/saved section </li>
                  <li> send message to another user </li>
                  <li> view global news from sidebar </li>
            </ul>
            <li>
              <div className="">
                Demo:
              </div>
            </li>


            <div className="img-thumbnail col-12 mt-3">
              <img src="mehhome.png" alt="Search bar & filter" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">home page</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="post.png" alt="Search result" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">write a post</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="mehchat.png" alt="Sticky note" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center mb-5">
              <h5 className="mt-2">chat with users that you follow</h5>
              </div>
            
              <div className="img-thumbnail col-12 mt-3">
              <img src="profile.png" alt="Sticky note" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center mb-5">
              <h5 className="mt-2">profile page</h5>
              </div>
            
            </div>
            </li>
          </ul>
        </div>
        </>
    )
}

export default Meh;