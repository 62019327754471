const NBody = () => {
    return (
        <>
            <div className="mb-5" id="nbody">
          <div className="d-flex justify-content-between">
            <h2 className="">NBody 🪐</h2>
            {/*<a href="https://www.penncoursecart.com/"> 
              <btn className="btn btn-secondary"> view project 🔗 </btn>
            </a>*/}
          </div>
          <ul>
            <li>
              <div className="">
                Simulation of celestial body interaction in solar system.
              </div>
            </li>
            <li>
              <div className="">
                Features
                <ul>
                  <li> Implemented Newton's Law using Java OOP</li>
                  <li> simulate movement of celestial bodies (Mercury through Mars) orbiting around the Sun </li>
            </ul>
            <li>
              <div className="">
                Demo:
              </div>
            </li>


            <div className="img-thumbnail col-12 mt-3">
              <img src="nbody1.png" alt="sudoku example 1" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Celestial bodies (Mercury through Mars) orbiting around the Sun </h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="nbody2.png" alt="sudoku example 1" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Position of planets after 5 seconds</h5>
              </div>
              </div>
            </li>
          </ul>
        </div>
        </>
    )
}

export default NBody;