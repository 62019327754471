const ML = () => {
    return (
        <>
            <div className="mb-5" id="ml">
          <div className="d-flex justify-content-between">
            <h2 className="">Machine Learning Algorithms</h2>
            <a href="https://github.com/yuntongf/ML-Algo"> 
              <btn className="btn btn-secondary"> see code 🔗 </btn>
            </a>
          </div>
          <ul>
            <li>
              <div className="">
              ML Algo implementations from class work: 
                <ul>
                  <li> Linear Regression, Ridge Regression</li>
                  <li> Gradient Descent </li>
                  <li> Neural Network </li>
                  <li> Convolutional Neural Networks </li>
                  <li> Classification </li>
                  <li> Image Classification </li>
            </ul>
            
            </div>
            </li>
          </ul>
        </div>
        </>
    )
}

export default ML;