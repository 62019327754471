const Contents  = () => {
    return (
        <div class="offcanvas show border-0 bg-transparent offcanvas-start" style={{width:"15%"}} data-bs-scroll="true"  tabindex="-1" aria-labelledby="offcanvasScrollingLabel">
        <div class="col-12 mt-5 ms-5">
            <h3 className="mt-5 ms-2 mb-3">Contents</h3>
            <div id="projects" className="list-group">
              <a class="list-group-item list-group-item-action" href="#pass-at-penn">Pass@Penn (TypeScript)</a>
              <a class="list-group-item list-group-item-action" href="#penn-pals">Penn Pals (JavaScript)</a>
              <a class="list-group-item list-group-item-action" href="#ml">ML Algos (Python)</a>
              <a class="list-group-item list-group-item-action" href="#heatmap"> Pennsylvania Pollution Map (Python)</a>
              <a class="list-group-item list-group-item-action" href="#fulton-bank"> Fulton Bank Risk Management (Python)</a>
              <a class="list-group-item list-group-item-action" href="#meh">Meh~ (TypeScript)</a>
              {/* <a class="list-group-item list-group-item-action" href="#penn-course-cart">Penn Course Cart (JavaScript)</a> */}
              <a class="list-group-item list-group-item-action" href="#sudoku">Sudoku (Java)</a>
              {/* <a class="list-group-item list-group-item-action" href="#nbody">NBody (Java)</a> */}
              <a class="list-group-item list-group-item-action" href="#green-miles">Green Miles (JavaScript)</a>
              <a class="list-group-item list-group-item-action" href="#lc4-simulator">LC4 simulator (C)</a>
              <a class="list-group-item list-group-item-action" href="#loudness-war">Loudness War (SQL & Python)</a>
            </div>
          </div>
      </div>
    )
}

export default Contents;