const Loudness = () => {
    return (
        <>
             <div className="mb-5" id="loudness-war">
          <div className="d-flex justify-content-between">
            <h2 className=""> Loudness War 🎸</h2>
            {/*<a href="https://www.penncoursecart.com/"> 
              <btn className="btn btn-secondary"> view project 🔗 </btn>
            </a>*/}
          </div>
          <ul>
            <li>
              <div className="">
              {`Analyzed `}
              <a href="https://www.kaggle.com/datasets/leonardopena/top-spotify-songs-from-20102019-by-year">
                  data 
                </a>
              {` to`} understand the relationship between the numerical parameters of 
              music such as loudness, danceability, energy, etc. 
              and see how they are related to a song's popularity
              </div>
            </li>

            <li>
              <div className="">
                Demo:
              </div>
            </li>

            <div className="img-thumbnail col-12 mt-3">
              <img src="data.png" alt="data set" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Data set </h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="corr.png" alt="correlation" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> EDA: correlation between variables (Python) </h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="count.png" alt="count by db" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Count by db (SQL) </h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="loudest.png" alt="loudest" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Loudest songs (SQL)</h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="correlation.png" alt="correlation with popularity" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Correlation of each factor with popularity (SQL)</h5>
              </div>

          </ul>
        
        </div> 
        </>
    )
}

export default Loudness;