const Header = () => {
    return (
        <>
            <div className="d-flex justify-content-center">
                <h1 className=" ms-5 mt-0 me-5">August's Project Portfolio</h1>
            </div>
            <div className="d-flex justify-content-center mb-4">
                <div className="mt-1">
                    <a className="text-dark text-decoration-none" href="https://github.com/yuntongf">
                        <img src="download.png" alt="GitHub" style={{height: 23, width: 23}}></img>
                        {' yuntongf |'} 
                    </a>
                    <a className="text-dark" href="https://drive.google.com/file/d/1qBpekTFOtXkJDFSCTBwcPxgQj6InskaC/view?usp=sharing">
                        <img src="link.png" alt="link" style={{height: 25, width: 25}}></img>
                        <span>Reference from Professor Xi Song</span>
                    </a>

                    {`  | ✉️  yuntongf@gmail.com`}
                </div>
            </div>
        </>
    )
}

export default Header;