const Fulton = () => {
    return (
        <>
             <div className="mb-5" id="fulton-bank">
          <div className="d-flex justify-content-between">
            <h2 className="">Fulton Bank Risk Management 📈</h2>
            {/*<a href="https://www.penncoursecart.com/"> 
              <btn className="btn btn-secondary"> view project 🔗 </btn>
            </a>*/}
          </div>
          <ul>
            <li>
              <div className="">
              Streamlined customer default behavior prediction model 
              and improved accuracy by 12.2% using oversampling with decision 
              tree and gradient boosting in Python, working closely with 4 Fulton 
              Bank quantitative analysts on risk management team
              </div>
            </li>
            <li>
              <div className="">
              Presented the improved model to Fulton Bank executives 
              and Wharton professors with teammates 
              </div>
            </li> 
                
            <li>
              <div className="">
                Demo:
              </div>
            </li>
            <div className="img-thumbnail col-12 mt-3">
              <img src="process.png" alt="our process" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">The process our team followed (from final presentation slides)</h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="models.png" alt="models" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Models that we used (I worked on decision tree and gradient boosting)</h5>
              </div> 

              <div className="img-thumbnail col-12 mt-3">
              <img src="accuracy.png" alt="accuracy" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Metrics for used models measured on small business data</h5>
              </div> 


          </ul>
        </div>
        </>
    )
}

export default Fulton;