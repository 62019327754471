const LC4 = () => {
    return (
        <>
            <div className="mb-5" id="lc4-simulator">
          <div className="d-flex justify-content-between">
            <h2 className="">Little Computer 4 Simulator 💻</h2>
            {/*<a href="https://www.penncoursecart.com/"> 
              <btn className="btn btn-secondary"> view project 🔗 </btn>
            </a>*/}
          </div>
          <ul>
            <li>
              <div className="">
                Simulation of Penn's Little Computer 4 using C
              </div>
            </li>
            <li>
              <div className="">
                Features
                <ul>
                  <li> convert object file to structured assembly language </li>
                  <li> simulate running of a simple computer with register files, data & instruction memory, Arithmetic Logic Unit (ALU), and Program Counter (PC)</li>
            </ul>
            <li>
              <div className="">
                Demo:
              </div>
            </li>

            <div className="img-thumbnail col-12 mt-3">
              <img src="obj-file.png" alt="object file" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Object file </h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="assem-file.png" alt="assembly file" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Assembly file </h5>
              </div>

              <div className="img-thumbnail col-12 mt-3">
              <img src="data-mem.png" alt="data memory" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Data memory </h5>
              </div>


              </div>
            </li>
          </ul>
        </div>
        </>
    )
}

export default LC4;