const PennPals = () => {
    return (
        <>
            <div className="mb-2" id="penn-pals">
          
          <div className="d-flex justify-content-between">
            <h2 className="">Penn Pals 📝</h2>
            <a href="https://www.pennpals.app/"> 
              <btn className="btn btn-secondary"> try project 🔗 </btn>
            </a>
          </div>
          
          <ul>
            <li>
              <div className="">
                <div> A 3-page public message board for Penn students  </div>
                Users can: 
                <ul>
                  <li> register with unqiue username and a password with at least 3 characters</li>
                  <li> log-in info is remembered </li>
                  <li> log in with unqique username and password </li>
                  <li> post messages </li>
                  <li> like and downvote messages </li>
                  <li> post comment </li>
                  <li> like and downvote comments </li>
                  <li> check liked messages </li>
                </ul>
                Admins can:
                <ul>
                  <li> manage users </li>
                  <li> check number of downvotes and delete inappropriate posts</li>
                </ul>
                
              </div>
            </li>

            <li>
              <div className="">
                Demo:
              </div>
            </li>

            <div className="img-thumbnail col-12 mt-3">
              <img src="pennpals-login.jpg" alt="Penn Pals login" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Login</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="pennpals-message-board.png" alt="Penn Pals login" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Message board</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="pennpals-compose.png" alt="Post a messasage" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Post</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="pennpals-reply.png" alt="Post a messasage" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Reply</h5>
            </div>

          </ul>
        </div>
        </>
    )
}

export default PennPals;