const PassAtPenn = () => {
    return (
        <>
            <div className="mb-5" id="pass-at-penn">
          <div className="d-flex justify-content-between">
            <h2 className="">Pass@Penn ✅</h2>
            <a href="https://www.passatpenn.com/"> 
              <btn className="btn btn-secondary"> try project 🔗 </btn>
            </a>
          </div>
          <ul>
            <li>
              <div className="">
                A course planning platform that connects to Penn Registrar’s backend
              </div>
            </li>
            <li>
              <div className="">
                Previously Penn Course Cart
              </div>
            </li>
            <li>
              <div className="">
              Users can: 
                <ul>
                  <li> search and sort courses </li>
                  <li> view difficulty, workload, and quality 
              scores incorporated from existing reviews on PennCourseReview </li>
                  <li> create/edit/delete
               sticky notes for individual course selection </li>
                  <li> add courses to cart </li>
                  <li> checkout courses in cart </li>
            </ul>
            <li>
              <div className="">
                Demo:
              </div>
            </li>


            <div className="img-thumbnail col-12 mt-3">
              <img src="pcc-search-bar.png" alt="Search bar & filter" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Search bar & filter</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="pcc-search-result.png" alt="Search result" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Search result & course detail</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="pcc-stickies.png" alt="Sticky note" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center mb-5">
              <h5 className="mt-2">Take sticky notes to make better choice for course selection</h5>
              </div>
            
              <div className="img-thumbnail col-12 mt-3">
              <img src="dnd.png" alt="Sticky note" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center mb-5">
              <h5 className="mt-2">Drag & drop to plan courses</h5>
              </div>
            
            </div>
            </li>
          </ul>
        </div>
        </>
    )
}

export default PassAtPenn;