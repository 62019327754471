const GreenMiles = () => {
    return (
        <>
            <div className="mb-2" id="green-miles">
          <div className="d-flex justify-content-between">
            <h2 className=""> Green Miles 👕</h2>
          </div>
          
          <ul>
            <li>
              <div className="">
                <div> A second-hand goods marketplace to encourage sustainable development </div>
              </div>
            </li>
            <li>
              <div className="">
              Developed under 36-hr time constraint during PennApps heckathon
              </div>
            </li>
            <li>
                Users can: 
                <ul>
                  <li> register with unqiue username and a password with at least 3 characters</li>
                  <li> log-in info is remembered </li>
                  <li> log in with unqique username and password </li>
                  <li> sell second-hand goods </li>
                  <li> propose a price to buy an item </li>
                  <li> bargain with seller to settle a price </li>
                  <li> win greenmile points 🌱 by selling and buying goods </li>
                  <li> greenmile points 🌱 for each item on sale is calculated based on its price and demand:
                    more expensive goods in low demand will carry more points
                  </li>
                </ul>
            </li>

            <li>
              <div className="">
                Demo:
              </div>
            </li>

            <div className="img-thumbnail col-12 mt-3">
              <img src="gm-register.png" alt="GreenMiles register" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Register</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="gm-market.png" alt="GreenMiles marketplace" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Marketplace</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="gm-sell.png" alt="GreenMiles sell" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Sell</h5>
              </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="gm-buy.png" alt="GreenMiles buy" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2">Propose a price</h5>
            </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="gm-chat.png" alt="GreenMiles chat" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> Message page</h5>
            </div>

            <div className="img-thumbnail col-12 mt-3">
              <img src="gm-user-page.png" alt="GreenMiles user" style={{height: "100%", width: "100%"}}></img>
            </div>
              <div className="d-flex justify-content-center">
              <h5 className="mt-2"> User page</h5>
            </div>

          </ul>
        </div>
        </>
    )
}

export default GreenMiles;