const Sudoku = () => {
    return (
        <>
            <div className="mb-5" id="sudoku">
                <div className="d-flex justify-content-between">
                    <h2 className="">Sudoku ✏️</h2>
                    {/*<a href="https://www.penncoursecart.com/"> 
                    <btn className="btn btn-secondary"> view project 🔗 </btn>
                    </a>*/}
                </div>
                <ul>
                    <li>
                    <div className="">
                        An interactive soduku game with GUI
                    </div>
                    </li>
                    <li>
                    <div className="">
                        Users can:
                        <ul>
                        <li> click box and enter number </li>
                        <li> if a contradictory value is entered, the row/column/box that contain the value being repeated
                            will be marked yellow, while the repeated value will be marked red
                        </li>
                        <li> clear sudoku </li>
                        <li> based on the command line inputs, the sudoku can either be 6x6 or 9x9 </li>
                        <li> clear all previous entries by clicking the "clear" button </li>
                        <li> show "you win" message </li>
                    </ul>
                    <li>
                    <div className="">
                        Demo:
                    </div>
                    </li>


                    <div className="img-thumbnail col-12 mt-3">
                    <img src="sudoku1.png" alt="sudoku example 1" style={{height: "100%", width: "100%"}}></img>
                    </div>
                    <div className="d-flex justify-content-center">
                    <h5 className="mt-2">Sudoku example: 9 x 9 with repeated values in row and column</h5>
                    </div>

                    <div className="img-thumbnail col-12 mt-3">
                    <img src="sudoku2.png" alt="sudoku example 1" style={{height: "100%", width: "100%"}}></img>
                    </div>
                    <div className="d-flex justify-content-center">
                    <h5 className="mt-2">Sudoku example: 6 x 6 with repeated values in box and column</h5>
                    </div>
                    </div>
                    </li>
                </ul>
                </div>
        </>
    )
}

export default Sudoku;