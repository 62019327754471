import "./App.css";
import {React} from "react";
import "bootstrap/dist/css/bootstrap.css";
import Contents from "./Contents";
import Header from "./Header";
import PennPals from './projects/PennPals'
import Fulton from "./projects/Fulton";
import Sudoku from "./projects/Sudoku";
import NBody from "./projects/NBody";
import LC4 from "./projects/LC4";
import Loudness from "./projects/Loudness";
import GreenMiles from "./projects/GreenMiles";
import PassAtPenn from "./projects/PassAtPenn";
import Meh from "./projects/Meh";
import ML from "./projects/ML";
import Heatmap from "./projects/Heatmap";


function App() {
  return (
    <div className="col-12 d-flex justify-content-center">
      <Contents/>
      <div className="col-6 ms-2 mt-5 d-flex flex-column">
        <Header/>
        <div data-spy="scroll" data-target="#projects" data-offset="0" data-smooth-scroll="true" style={{"position" : "relative"}}>
          <PassAtPenn/>
          
          <PennPals/>

          <ML/>

          <Heatmap/>

          <Fulton/>

          <Meh/>

          <Sudoku/>

          <GreenMiles/>


          <LC4/>

          <Loudness/>
        </div>s
      </div>
    </div>
  );
}

export default App;
